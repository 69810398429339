<template>
  <v-container
    id=""
    grid-list-xs
  >
    <v-row>
      <v-col
        cols="12"
        md="8"
        class="pt-0"
      >
        <v-card
          elevation="0"
          style="border: 1px solid #e3e1e4;"
        >
          <v-container grid-list-xs>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="5">
                    <div class="d-block">
                      <p class="mb-0 d-flex justify-end text-h6">
                        <b>{{ $t('msg.flightCon') }}</b>
                      </p>
                      <p class="mb-0 d-flex justify-end text-h6">
                        <b
                          v-if="
                            aerolineCotizadorRegresoFlight[indAero].flights_ida[indexTarifaIda].tarifas_contrate[
                              aerolineCotizadorRegresoFlight[indAero].indTarifaIda
                            ].tarifa_id ===
                              aerolineCotizadorRegresoFlight[indAero].flights_regreso[indexTarifaRegreso]
                                .tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso].tarifa_id
                          "
                        >{{ $t('lbl.tarif') }}
                          {{
                            aerolineCotizadorRegresoFlight[indAero].flights_regreso[indexTarifaRegreso]
                              .tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso].tarifa
                          }}</b>
                        <b v-else>{{ $t('msg.tarifasMulti') }}</b>
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <v-divider vertical></v-divider>
                  </v-col>
                  <v-col cols="5">
                    <div class="d-block">
                      <p
                        v-if="showOnlyGestor"
                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                      >
                        {{ priceAdult | currency }}
                      </p>
                      <p
                        v-else
                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                        style="color: #FFFFFF"
                      >
                        .
                      </p>
                      <div class="pr-6 d-flex justify-end">
                        <p class="mb-0 mr-5 d-flex justify-center align-center">
                          <b>{{ priceAdult | currency }}</b>
                        </p>
                        <v-tooltip
                          v-if="showOnlyGestor"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiInformation }}
                            </v-icon>
                          </template>
                          <span>
                            <div class="d-block">
                              <div class="d-flex justify-start align-center">
                                <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                              </div>
                              <div class="d-flex justify-space-between align-center">
                                <p class="mb-0 pr-6">Tarifa</p>
                                <p class="mb-0">776,87 $</p>
                              </div>
                              <div class="d-flex justify-space-between align-center">
                                <p
                                  class="mb-0 pr-6"
                                  subtitle-2
                                >Impuestos y tasas</p>
                                <p
                                  class="mb-0"
                                  subtitle-2
                                >776,87 $</p>
                              </div>
                              <v-divider
                                color="white"
                                class="mt-2"
                              ></v-divider>
                              <div class="d-flex justify-space-between align-center">
                                <p class="my-2"><b>Total</b></p>
                                <p
                                  class="my-2"
                                  subtitle-2
                                >776,87 $</p>
                              </div>
                              <div
                                style="background-color: #ffffff6b"
                                class="mb-4 pa-2"
                              >
                                <div class="d-flex justify-space-between align-center">
                                  <p class="mb-0">Tu comisión</p>
                                  <p
                                    class="mb-0"
                                    subtitle-2
                                  >776,87 $</p>
                                </div>
                                <div class="d-flex justify-space-between align-center">
                                  <p class="mb-0"><b>PVP</b></p>
                                  <p
                                    class="mb-0"
                                    subtitle-2
                                  >776,87 $</p>
                                </div>
                              </div>
                            </div>
                          </span>
                        </v-tooltip>
                      </div>
                      <!--
                  <a href="#">
                    <p
                      style="position: absolute; bottom:15px; right: 35px;"
                      class="p14Mine mb-0 d-flex justify-center align-center"
                    >
                      Reglas de la tarifa
                    </p>
                  </a>-->
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Card tarifa desktop
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="10">
                    <div class="d-block">
                      <p class="mb-0 d-flex justify-start text-h6">
                        <b>{{ $t('booking.ida') }}</b>
                      </p>
                      <p class="mb-0 d-flex justify-start">
                        <b>{{
                          aerolineCotizadorRegresoFlight[indAero].flights_ida[indexTarifaIda].tarifas_contrate[
                            aerolineCotizadorRegresoFlight[indAero].indTarifaIda
                          ].tarifa
                        }}</b>
                      </p>
                      <p class="mb-0 d-flex justify-start">
                        {{ $t('lbl.clase') }}:
                        <b class="pl-2">{{
                          aerolineCotizadorRegresoFlight[indAero].flights_ida[indexTarifaIda].tarifas_contrate[
                            aerolineCotizadorRegresoFlight[indAero].indTarifaIda
                          ].clase
                        }}</b>
                      </p>
                    </div>
                    <v-row class="pt-4">
                      <v-col cols="6">
                        <div
                          v-if="
                            aerolineCotizadorRegresoFlight[indAero].flights_ida[indexTarifaIda].tarifas_contrate[
                              aerolineCotizadorRegresoFlight[indAero].indTarifaIda
                            ].cant_maletas_bodega > 0
                          "
                          class="d-flex justify-start align-center"
                        >
                          <v-icon color="primary">
                            {{ icons.mdiBagSuitcase }}
                          </v-icon>
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('lbl.bag') }}:
                            <span class="text-capitalize">
                              {{
                                $tc(
                                  'lbl.pieza',
                                  aerolineCotizadorRegresoFlight[indAero].flights_ida[indexTarifaIda].tarifas_contrate[
                                    aerolineCotizadorRegresoFlight[indAero].indTarifaIda
                                  ].cant_maletas_bodega,
                                )
                              }}
                            </span>
                          </p>
                        </div>
                        <div
                          v-else
                          class="d-flex justify-start align-center"
                        >
                          <v-icon color="default">
                            {{ icons.mdiBagSuitcaseOffOutline }}
                          </v-icon>
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('lbl.bag') }}: {{ $t('lbl.bagNot') }}
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div
                          v-if="
                            aerolineCotizadorRegresoFlight[indAero].flights_ida[indexTarifaIda].tarifas_contrate[
                              aerolineCotizadorRegresoFlight[indAero].indTarifaIda
                            ].cant_maletas_cabina > 0
                          "
                          class="d-flex justify-start align-center"
                        >
                          <v-icon color="primary">
                            {{ icons.mdiBagPersonal }}
                          </v-icon>
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('lbl.bagHand') }}: incluido
                          </p>
                        </div>
                        <div
                          v-else
                          class="d-flex justify-start align-center"
                        >
                          <v-icon color="default">
                            {{ icons.mdiBagPersonalOffOutline }}
                          </v-icon>
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('lbl.bagHand') }}: no incluido
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <div class="d-block">
                      <p
                        v-if="showOnlyGestor"
                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                      >
                        {{ priceAdult | currency }}
                      </p>
                      <p
                        v-else
                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                        style="color: #FFFFFF"
                      >
                        .
                      </p>
                      <div class="pr-6 d-flex justify-end">
                        <p class="mb-0 mr-5 d-flex justify-center align-center">
                          <b>{{ priceAdult | currency }}</b>
                        </p>
                        <v-tooltip
                          v-if="showOnlyGestor"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiInformation }}
                            </v-icon>
                          </template>
                          <span>
                            <div class="d-block">
                              <div class="d-flex justify-start align-center">
                                <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                              </div>
                              <div class="d-flex justify-space-between align-center">
                                <p class="mb-0 pr-6">Tarifa</p>
                                <p class="mb-0">776,87 $</p>
                              </div>
                              <div class="d-flex justify-space-between align-center">
                                <p
                                  class="mb-0 pr-6"
                                  subtitle-2
                                >Impuestos y tasas</p>
                                <p
                                  class="mb-0"
                                  subtitle-2
                                >776,87 $</p>
                              </div>
                              <v-divider
                                color="white"
                                class="mt-2"
                              ></v-divider>
                              <div class="d-flex justify-space-between align-center">
                                <p class="my-2"><b>Total</b></p>
                                <p
                                  class="my-2"
                                  subtitle-2
                                >776,87 $</p>
                              </div>
                              <div
                                style="background-color: #ffffff6b"
                                class="mb-4 pa-2"
                              >
                                <div class="d-flex justify-space-between align-center">
                                  <p class="mb-0">Tu comisión</p>
                                  <p
                                    class="mb-0"
                                    subtitle-2
                                  >776,87 $</p>
                                </div>
                                <div class="d-flex justify-space-between align-center">
                                  <p class="mb-0"><b>PVP</b></p>
                                  <p
                                    class="mb-0"
                                    subtitle-2
                                  >776,87 $</p>
                                </div>
                              </div>
                            </div>
                          </span>
                        </v-tooltip>
                      </div>
                      <p
                        v-if="showOnlyGestor"
                        class="p12Mine"
                        style="color:#ce2121"
                      >
                        {{ $t('lbl.cupo') }} ({{ aerolineCotizadorRegresoFlight[indAero].flights_ida[indexTarifaIda].tarifas_contrate[
                          aerolineCotizadorRegresoFlight[indAero].indTarifaIda
                        ].cupo_disponible }})
                      </p>
                      !--
                  <a href="#">
                    <p
                      style="position: absolute; bottom:15px; right: 35px;"
                      class="p14Mine mb-0 d-flex justify-center align-center"
                    >
                      Reglas de la tarifa
                    </p>
                  </a>--
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col cols="10">
                    <div class="d-block">
                      <p class="mb-0 d-flex justify-start text-h6">
                        <b>{{ $t('booking.back') }}</b>
                      </p>
                      <p class="mb-0 d-flex justify-start">
                        <b>{{
                          aerolineCotizadorRegresoFlight[indAero].flights_regreso[indexTarifaRegreso].tarifas_contrate[
                            aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso
                          ].tarifa
                        }}</b>
                      </p>
                      <p class="mb-0 d-flex justify-start">
                        {{ $t('lbl.clase') }}:
                        <b class="pl-2">{{
                          aerolineCotizadorRegresoFlight[indAero].flights_regreso[indexTarifaRegreso].tarifas_contrate[
                            aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso
                          ].clase
                        }}</b>
                      </p>
                    </div>
                    <v-row class="pt-4">
                      <v-col cols="6">
                        <div
                          v-if="
                            aerolineCotizadorRegresoFlight[indAero].flights_regreso[indexTarifaRegreso].tarifas_contrate[
                              aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso
                            ].cant_maletas_bodega > 0
                          "
                          class="d-flex justify-start align-center"
                        >
                          <v-icon color="primary">
                            {{ icons.mdiBagSuitcase }}
                          </v-icon>
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('lbl.bag') }}:
                            <span class="text-capitalize">
                              {{
                                $tc(
                                  'lbl.pieza',
                                  aerolineCotizadorRegresoFlight[indAero].flights_regreso[indexTarifaRegreso]
                                    .tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso]
                                    .cant_maletas_bodega,
                                )
                              }}
                            </span>
                          </p>
                        </div>
                        <div
                          v-else
                          class="d-flex justify-start align-center"
                        >
                          <v-icon color="default">
                            {{ icons.mdiBagSuitcaseOffOutline }}
                          </v-icon>
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('lbl.bag') }}: {{ $t('lbl.bagNot') }}
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div
                          v-if="
                            aerolineCotizadorRegresoFlight[indAero].flights_regreso[indexTarifaRegreso].tarifas_contrate[
                              aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso
                            ].cant_maletas_cabina > 0
                          "
                          class="d-flex justify-start align-center"
                        >
                          <v-icon color="primary">
                            {{ icons.mdiBagPersonal }}
                          </v-icon>
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('lbl.bagHand') }}: incluido
                          </p>
                        </div>
                        <div
                          v-else
                          class="d-flex justify-start align-center"
                        >
                          <v-icon color="default">
                            {{ icons.mdiBagPersonalOffOutline }}
                          </v-icon>
                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                            {{ $t('lbl.bagHand') }}: no incluido
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  !--VALORES--
                  <v-col
                    cols="2"
                  >
                    <span
                      v-if="showOnlyGestor"
                      class="p12Mine"
                      style="color:#ce2121"
                    >
                      {{ $t('lbl.cupo') }} ({{ aerolineCotizadorRegresoFlight[indAero].flights_regreso[indexTarifaRegreso].tarifas_contrate[
                        aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso
                      ].cupo_disponible }})
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>-->
          </v-container>
        </v-card>
      </v-col>

      <!--BTONES TARIFAS-->
      <v-col
        cols="12"
        md="4"
        class="pt-10"
      >
        <v-row>
          <v-col
            cols="2"
            class="pr-0"
          >
            <a
              style="cursor: pointer"
              @click="insertPreferidos"
            >
              <v-icon
                large
                :color="idFlightFavorite.includes(aerolineCotizadorRegresoFlight[indAero].id_result) ? 'primary' : ''"
              >{{ idFlightFavorite.includes(aerolineCotizadorRegresoFlight[indAero].id_result) ? icons.mdiHeart : icons.mdiHeartOutline, }}</v-icon>
            </a>
          </v-col>
          <v-col
            cols="10"
            class="pr-0"
          >
            <v-btn
              color="primary"
              width="100%"
              class="mb-2"
              @click="setFlight"
            >
              {{ $t('btn.select') }}
            </v-btn>
          </v-col>
        </v-row>
        <!--<div class="d-flex justify-center align-center">
          <v-container grid-list-xs>
            <v-row>
              <v-col
                cols="2"
                class="pr-0"
              >
                <a
                  style="cursor: pointer"
                  @click="insertPreferidos"
                >
                  <v-icon
                    large
                    :color="idFlightFavorite.includes(aerolineCotizadorRegresoFlight[indAero].id_result) ? 'primary' : ''"
                  >{{ idFlightFavorite.includes(aerolineCotizadorRegresoFlight[indAero].id_result) ? icons.mdiHeart : icons.mdiHeartOutline, }}</v-icon>
                </a>
              </v-col>
              <v-col
                cols="10"
                class="pr-0"
              >
                !-- Modal!! --
                <v-dialog
                  v-model="dialog"
                  width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#e3e1e4"
                      width="100%"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('lbl.otherTarifa') }}<v-icon>{{ icons.mdiChevronDown }} </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-icon
                      large
                      class="mr-5 mt-4"
                      style="position:fixed; top:2rem; right:2rem; z-index:9999"
                      @click="dialog = false"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-card-text class="mt-4 grey lighten-2 pb-0 text-h6 px-0">
                            {{ $t('booking.ida') }}: {{ destinosCotizadorFlight[0].origen.nombre }} ({{
                              destinosCotizadorFlight[0].origen.iata
                            }})
                            <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                            {{ destinosCotizadorFlight[0].destino.nombre }}
                            ({{ destinosCotizadorFlight[0].destino.iata }})
                          </v-card-text>
                          <v-card-text class="mt-0 grey lighten-2 pb-0 text-h6 px-0">
                            {{ destinosCotizadorFlight[0].from | moment('MMMM D, YYYY, dddd') }}
                          </v-card-text>
                          !-- Card tarifa desktop --
                          <v-radio-group
                            v-model="radioGroupIda"
                            mandatory
                          >
                            <v-card
                              v-for="(idaTarifa, i) in aerolineCotizadorRegresoFlight[indAero].flights_ida[
                                indexTarifaIda
                              ].tarifas_contrate"
                              :key="i"
                              style="border:1px solid #e3e1e4"
                              class="my-2"
                            >
                              <v-container grid-list-xs>
                                <v-row>
                                  <v-col cols="6">
                                    <div class="d-block">
                                      <p class="mb-0 d-flex justify-start">
                                        <v-radio
                                          label=""
                                          dense
                                          :value="i"
                                          @click="setRadioIda(i)"
                                        ></v-radio>
                                        <b class="pl-2">{{ idaTarifa.clase }}</b>
                                      </p>
                                      <p class="mb-0 d-flex justify-start">
                                        <b>{{ $t('lbl.tarif') }}: {{ idaTarifa.tarifa }}</b>
                                      </p>
                                    </div>
                                    <v-row class="pt-4">
                                      <v-col
                                        cols="12"
                                        class="pb-0"
                                      >
                                        <div
                                          v-if="idaTarifa.cant_maletas_bodega > 0"
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiBagSuitcase }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bag') }}:
                                            <span class="text-capitalize">
                                              {{ $tc('lbl.pieza', idaTarifa.cant_maletas_bodega) }}
                                            </span>
                                          </p>
                                        </div>
                                        <div
                                          v-else
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="default">
                                            {{ icons.mdiBagSuitcaseOffOutline }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bag') }}: {{ $t('lbl.bagNot') }}
                                          </p>
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <div
                                          v-if="idaTarifa.cant_maletas_cabina > 0"
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiBagPersonal }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bagHand') }}: incluido
                                          </p>
                                        </div>
                                        <div
                                          v-else
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="default">
                                            {{ icons.mdiBagPersonalOffOutline }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bagHand') }}: no incluido
                                          </p>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="6">
                                    <div class="d-block">
                                      <p
                                        v-if="showOnlyGestor"
                                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                      >
                                        {{ idaTarifa.price_total | currency }}
                                      </p>
                                      <p
                                        v-else
                                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                        style="color: #FFFFFF"
                                      >
                                        .
                                      </p>
                                      <div class="pr-6 d-flex justify-end">
                                        <p class="mb-0 mr-5 d-flex justify-center align-center">
                                          <b>{{ idaTarifa.price_total | currency }}</b>
                                        </p>
                                        <v-tooltip
                                          v-if="showOnlyGestor"
                                          top
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                              color="primary"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              {{ icons.mdiInformation }}
                                            </v-icon>
                                          </template>
                                          <span>
                                            <div class="d-block">
                                              <div class="d-flex justify-start align-center">
                                                <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                              </div>
                                              <div class="d-flex justify-space-between align-center">
                                                <p class="mb-0 pr-6">Tarifa</p>
                                                <p class="mb-0">776,87 $</p>
                                              </div>
                                              <div class="d-flex justify-space-between align-center">
                                                <p
                                                  class="mb-0 pr-6"
                                                  subtitle-2
                                                >Impuestos y tasas</p>
                                                <p
                                                  class="mb-0"
                                                  subtitle-2
                                                >776,87 $</p>
                                              </div>
                                              <v-divider
                                                color="white"
                                                class="mt-2"
                                              ></v-divider>
                                              <div class="d-flex justify-space-between align-center">
                                                <p class="my-2"><b>Total</b></p>
                                                <p
                                                  class="my-2"
                                                  subtitle-2
                                                >776,87 $</p>
                                              </div>
                                              <div
                                                style="background-color: #ffffff6b"
                                                class="mb-4 pa-2"
                                              >
                                                <div class="d-flex justify-space-between align-center">
                                                  <p class="mb-0">Tu comisión</p>
                                                  <p
                                                    class="mb-0"
                                                    subtitle-2
                                                  >776,87 $</p>
                                                </div>
                                                <div class="d-flex justify-space-between align-center">
                                                  <p class="mb-0"><b>PVP</b></p>
                                                  <p
                                                    class="mb-0"
                                                    subtitle-2
                                                  >776,87 $</p>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                        </v-tooltip>
                                      </div>
                                      <p
                                        v-if="showOnlyGestor"
                                        class="mb-0 pr-6 p12Mine d-flex justify-end"
                                        style="color:#ce2121"
                                      >
                                        {{ $t('lbl.cupoDisp') }} ({{ idaTarifa.cupo_disponible }})
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-radio-group>
                        </v-col>
                        !--BACKKK--
                        <v-col cols="6">
                          <v-card-text class="mt-4 grey lighten-2 pb-0 text-h6 px-0">
                            {{ $t('booking.back') }}: {{ destinosCotizadorFlight[0].destino.nombre }} ({{
                              destinosCotizadorFlight[0].destino.iata
                            }})
                            <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                            {{ destinosCotizadorFlight[0].origen.nombre }}
                            ({{ destinosCotizadorFlight[0].origen.iata }})
                          </v-card-text>
                          <v-card-text class="mt-0 grey lighten-2 pb-0 text-h6 px-0">
                            {{ destinosCotizadorFlight[0].to | moment('MMMM D, YYYY, dddd') }}
                          </v-card-text>
                          <v-radio-group
                            v-model="radioGroupBack"
                            mandatory
                          >
                            <v-card
                              v-for="(backTarifa, i) in aerolineCotizadorRegresoFlight[indAero].flights_regreso[
                                indexTarifaRegreso
                              ].tarifas_contrate"
                              :key="i"
                              style="border:1px solid #e3e1e4"
                              class="my-2"
                            >
                              <v-container grid-list-xs>
                                <v-row>
                                  <v-col cols="6">
                                    <div class="d-block">
                                      <p class="mb-0 d-flex justify-start">
                                        <v-radio
                                          label=""
                                          dense
                                          :value="i"
                                          @click="setRadioBack(i)"
                                        ></v-radio>
                                        <b class="pl-2">{{ backTarifa.clase }}</b>
                                      </p>
                                      <p class="mb-0 d-flex justify-start">
                                        <b>{{ $t('lbl.tarif') }}: {{ backTarifa.tarifa }}</b>
                                      </p>
                                    </div>
                                    <v-row class="pt-4">
                                      <v-col
                                        cols="12"
                                        class="pb-0"
                                      >
                                        <div
                                          v-if="backTarifa.cant_maletas_bodega > 0"
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiBagSuitcase }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bag') }}:
                                            <span class="text-capitalize">
                                              {{ $tc('lbl.pieza', backTarifa.cant_maletas_bodega) }}
                                            </span>
                                          </p>
                                        </div>
                                        <div
                                          v-else
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="default">
                                            {{ icons.mdiBagSuitcaseOffOutline }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bag') }}: {{ $t('lbl.bagNot') }}
                                          </p>
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <div
                                          v-if="backTarifa.cant_maletas_cabina > 0"
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiBagPersonal }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bagHand') }}: incluido
                                          </p>
                                        </div>
                                        <div
                                          v-else
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="default">
                                            {{ icons.mdiBagPersonalOffOutline }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bagHand') }}: no incluido
                                          </p>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="6">
                                    <div class="d-block">
                                      <p
                                        v-if="showOnlyGestor"
                                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                      >
                                        <b>{{ backTarifa.price_total | currency }}</b>
                                      </p>
                                      <p
                                        v-else
                                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                        style="color: #FFFFFF"
                                      >
                                        .
                                      </p>
                                      <div class="pr-6 d-flex justify-end">
                                        <p class="mb-0 mr-5 d-flex justify-center align-center">
                                          <b>{{ backTarifa.price_total | currency }}</b>
                                        </p>
                                        <v-tooltip
                                          v-if="showOnlyGestor"
                                          top
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                              color="primary"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              {{ icons.mdiInformation }}
                                            </v-icon>
                                          </template>
                                          <span>
                                            <div class="d-block">
                                              <div class="d-flex justify-start align-center">
                                                <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                              </div>
                                              <div class="d-flex justify-space-between align-center">
                                                <p class="mb-0 pr-6">Tarifa</p>
                                                <p class="mb-0">776,87 $</p>
                                              </div>
                                              <div class="d-flex justify-space-between align-center">
                                                <p
                                                  class="mb-0 pr-6"
                                                  subtitle-2
                                                >Impuestos y tasas</p>
                                                <p
                                                  class="mb-0"
                                                  subtitle-2
                                                >776,87 $</p>
                                              </div>
                                              <v-divider
                                                color="white"
                                                class="mt-2"
                                              ></v-divider>
                                              <div class="d-flex justify-space-between align-center">
                                                <p class="my-2"><b>Total</b></p>
                                                <p
                                                  class="my-2"
                                                  subtitle-2
                                                >776,87 $</p>
                                              </div>
                                              <div
                                                style="background-color: #ffffff6b"
                                                class="mb-4 pa-2"
                                              >
                                                <div class="d-flex justify-space-between align-center">
                                                  <p class="mb-0">Tu comisión</p>
                                                  <p
                                                    class="mb-0"
                                                    subtitle-2
                                                  >776,87 $</p>
                                                </div>
                                                <div class="d-flex justify-space-between align-center">
                                                  <p class="mb-0"><b>PVP</b></p>
                                                  <p
                                                    class="mb-0"
                                                    subtitle-2
                                                  >776,87 $</p>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                        </v-tooltip>
                                      </div>
                                      <p
                                        v-if="showOnlyGestor"
                                        class="mb-0 pr-6 p12Mine d-flex justify-end"
                                        style="color:#ce2121"
                                      >
                                        {{ $t('lbl.cupoDisp') }} ({{ backTarifa.cupo_disponible }})
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </div>-->
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiChevronUp,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
  mdiInformationVariant,
  mdiBagSuitcaseOffOutline,
  mdiHeart,
  mdiHeartOutline,
  mdiBagPersonalOffOutline,
  mdiArrowRight,
  mdiClose,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    indexTarifaIda: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    indexTarifaRegreso: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      showOnlyGestor: true,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      radioGroupBack: 0,
      items: [],
      showOtherTarifas: false,
      dialog: false,
      rowShowOtherTarifas: -1,
      radioGroupIda: 0,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiChevronUp,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
        mdiInformationVariant,
        mdiBagSuitcaseOffOutline,
        mdiHeart,
        mdiHeartOutline,
        mdiBagPersonalOffOutline,
        mdiArrowRight,
        mdiClose,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      resultCotizadorIdaFlight: state => state.app.resultCotizadorIdaFlight,
      tarifaEconCotizadorIdaFlight: state => state.app.tarifaEconCotizadorIdaFlight,
      aerolineCotizadorRegresoFlight: state => state.app.aerolineCotizadorRegresoFlight,
      cotizadorFlightFavorite: state => state.app.cotizadorFlightFavorite,
      idFlightFavorite: state => state.app.idFlightFavorite,
    }),

    // eslint-disable-next-line vue/no-dupe-keys
    priceAdult() {
      const price = parseFloat(
        this.aerolineCotizadorRegresoFlight[this.indAero].flights_ida[this.indexTarifaIda].tarifas_contrate[
          this.aerolineCotizadorRegresoFlight[this.indAero].indTarifaIda
        ].price_inicial,
      )
        + parseFloat(
          this.aerolineCotizadorRegresoFlight[this.indAero].flights_regreso[this.indexTarifaRegreso].tarifas_contrate[
            this.aerolineCotizadorRegresoFlight[this.indAero].indTarifaRegreso
          ].price_inicial,
        )

      return price
    },
  },
  created() {
    window.addEventListener('keydown', e => {
      if ((e.key === 'r' || e.key === 'R') && e.shiftKey) {
        this.changePrice()
      }
    })
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'insertCotizadorFlightFavorite',
      'deleteCotizadorFlightFavorite',
      'setCotizadorFlightSelect',
      'setIndTarifaEconCotizadorIdaFligth',
      'insertIdFlightFavorite',
      'deleteIdFlightFavorite',
    ]),
    openTarifasContrate(indexFlight, indexAeroline) {
      // this.aerolineCotizadorRegresoFlight[indexAeroline].flights_ida[indexFlight].tarifas_contrate
      // console.log('indexFlight', indexFlight)
      // console.log('indexAeroline', indexAeroline)
      this.showOtherTarifas = !this.showOtherTarifas
      this.rowShowOtherTarifas = indexAeroline
    },
    insertPreferidos() {
      const destinos = []
      this.destinosCotizadorFlight.forEach(element => {
        destinos.push({
          origen: element.origen,
          destino: element.destino,
          from: element.from,
          to: element.to,
        })
      })

      // console.log(this.aerolineCotizadorRegresoFlight[this.indAero])
      const json = {
        cant_adults: this.cotizadorFlight.cant_adults,
        cant_ninnos: this.cotizadorFlight.cant_ninnos,
        cant_infant: this.cotizadorFlight.cant_infant,
        aerolinea: this.cotizadorFlight.aerolinea,
        onlyIda: this.cotizadorFlight.onlyIda,
        destinosMultiples: this.cotizadorFlight.destinosMultiples,
        destinos,
        item_ida: this.aerolineCotizadorRegresoFlight[this.indAero].flights_ida[this.indexTarifaIda],
        indTarifaIda: this.aerolineCotizadorRegresoFlight[this.indAero].indTarifaIda,
        item_regreso: this.aerolineCotizadorRegresoFlight[this.indAero].flights_regreso[this.indexTarifaRegreso],
        indTarifaRegreso: this.aerolineCotizadorRegresoFlight[this.indAero].indTarifaRegreso,
        id_result: this.aerolineCotizadorRegresoFlight[this.indAero].id_result,
      }
      if (!this.idFlightFavorite.includes(this.aerolineCotizadorRegresoFlight[this.indAero].id_result)) {
        this.insertCotizadorFlightFavorite(json)
        this.insertIdFlightFavorite(this.aerolineCotizadorRegresoFlight[this.indAero].id_result)
      } else {
        this.deleteCotizadorFlightFavorite(json)
        this.deleteIdFlightFavorite(this.aerolineCotizadorRegresoFlight[this.indAero].id_result)
      }
    },
    setFlight() {
      const json = {
        cant_adults: this.cotizadorFlight.cant_adults,
        cant_ninnos: this.cotizadorFlight.cant_ninnos,
        cant_infant: this.cotizadorFlight.cant_infant,
        aerolinea: this.cotizadorFlight.aerolinea,
        onlyIda: this.cotizadorFlight.onlyIda,
        destinosMultiples: this.cotizadorFlight.destinosMultiples,
        destinos: this.destinosCotizadorFlight,
        item_ida: this.aerolineCotizadorRegresoFlight[this.indAero].flights_ida[this.indexTarifaIda],
        indTarifa: this.aerolineCotizadorRegresoFlight[this.indAero].indTarifaIda,
        indRegreso: this.aerolineCotizadorRegresoFlight[this.indAero].indTarifaRegreso,
        item_regreso: this.aerolineCotizadorRegresoFlight[this.indAero].flights_regreso[this.indexTarifaRegreso],
      }

      // console.log(json)

      this.setCotizadorFlightSelect(json)

      this.$router.push({ name: 'selected-cotizador-flights' })
    },
    setRadioIda(index) {
      this.setIndTarifaIdaEconCotizadorIdaBackFligth({ indAero: this.indAero, index })
    },
    setRadioBack(index) {
      this.setIndTarifaBackEconCotizadorIdaBackFligth({ indAero: this.indAero, index })
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>
